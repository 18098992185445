import { mapState } from "vuex";
import { env } from 'md-base-tools/env';
import { projectVote } from "@/api/index";
import { goToLogin } from "@/utils/user";
import { fmTime, PreviewImages, mdToast, mdAlert } from '@/utils/tools';
import imgUrlFilter from 'md-base-tools/imgUrlFilter';
export default {
  props: {
    id: {
      // 更新id | 创意id
      type: String,
      default: ''
    },
    quest_info: {
      type: Object
    },
    vote_type: {
      // 1 创意 2 众筹
      type: String,
      default: '2'
    }
  },
  computed: {
    ...mapState({
      ifLogin: state => state.user.ifLogin,
      // 是否登录
      userId: state => state.user.userId,
      token: state => state.user.token,
      linkUrl: state => state.user.linkUrl
    }),
    isPhone() {
      return env.isModianIos() || env.isModianAndroid();
    },
    endTimeShow() {
      if (this.questInfo.if_end == 1) {
        return '';
      } else {
        return fmTime(this.endtime);
      }
    }
  },
  data() {
    return {
      questInfo: this.quest_info,
      checked_vote_items: [],
      //投票选中数据
      showLoading: false,
      rightMap: {
        0: '',
        1: '仅支持者参与投票',
        2: '部分档位支持者参与投票'
      },
      endtime: '',
      endtime_timer: null // 计时器
    };
  },
  mounted() {
    // 已结束
    if (this.questInfo.if_end != 1 && this.questInfo.etime) {
      const time = (new Date(this.questInfo.etime.replace(/-/g, '/')).getTime() - new Date().getTime()) / 1000;
      if (time > 0) {
        this.endtime = time;
        this.endtime_timer = setInterval(() => {
          if (this.endtime > 0) {
            this.endtime -= 1;
          } else {
            clearInterval(this.endtime_timer);
            window.location.reload();
          }
        }, 1000);
      }
    }
  },
  beforeUnmount() {
    clearInterval(this.endtime_timer);
  },
  methods: {
    checkVote(sItem, sindex, item) {
      const _this = this;
      if (this.ifLogin && this.userId) {
        // 投票结束
        if (this.questInfo.if_end == 1) return;
        // 已投过票
        if (item.is_select_vote) {
          console.log('取消投票！');
          mdAlert({
            title: "确定要取消投票吗?",
            actions: ['取消', '确定'],
            _callback: () => {
              let option = {
                item_update_id: _this.id,
                item_type: _this.vote_type,
                vote_id: item.id,
                op_type: 0
              };
              _this.doVote(option);
            }
          });
        } else {
          // 未投过票
          // 单选
          if (item.type == 0) {
            let option = {
              item_update_id: this.id,
              item_type: this.vote_type,
              vote_id: item.id,
              vote_item_ids: sItem.id,
              op_type: 1
            };
            this.doVote(option);
          } else {
            // 多选
            let index = this.checked_vote_items.findIndex(item => item == sItem.id);
            if (index > -1) {
              this.checked_vote_items.splice(index, 1);
            } else {
              if (item.max_select && this.checked_vote_items.length == item.max_select) {
                mdToast(`至多选择${item.max_select}项`);
              } else {
                this.checked_vote_items.push(sItem.id);
              }
            }
          }
        }
      } else {
        mdToast('请登录！');
        goToLogin();
      }
    },
    // 多选投票
    sub_vote(item) {
      const _this = this;
      if (this.ifLogin && this.userId) {
        if (item.min_select && this.checked_vote_items.length < item.min_select) {
          mdToast(`至少选择${item.min_select}项`);
          return;
        }
        if (item.max_select && this.checked_vote_items.length > item.max_select) {
          mdToast(`至多选择${item.max_select}项`);
          return;
        }
        let option = {
          item_update_id: _this.id,
          item_type: _this.vote_type,
          vote_id: item.id,
          vote_item_ids: _this.checked_vote_items.join(','),
          op_type: 1
        };
        _this.doVote(option);
      } else {
        mdToast('请登录！');
        goToLogin();
      }
    },
    // 投票
    async doVote(option) {
      if (this.showLoading) return;
      this.showLoading = true;
      const {
        status,
        message,
        data
      } = await projectVote(option);
      this.showLoading = false;
      if (status != 0) {
        mdToast(message);
      } else {
        mdToast(option.op_type == 1 ? message || '投票成功！' : message || '取消成功！');
        this.questInfo = data;
        this.checked_vote_items = [];
      }
    },
    imgUrlFilter() {
      return imgUrlFilter(...arguments);
    },
    showBigImg(index, img) {
      PreviewImages(0, [img]);
    }
  }
};