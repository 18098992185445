import MDRouter from "@/service/router";
import imgUrlFilter from 'md-base-tools/imgUrlFilter';
import { domPixelRatio } from 'md-base-tools/env';
// import { getNewDrawInfo } from "@api/index"
export default {
  props: {
    drawInfo: {
      type: Object
    }
    // drawId: { // 投票id
    //     type: String
    // },
    // drawImage: {
    //     type: String
    // },
    // drawTitle: {
    //     type: String
    // },
    // updateId: {
    //     type: [Number, String]
    // }
  },
  // computed: {
  //     showImg() {
  //         return this.drawImage || this.img
  //     },
  //     showTit() {
  //         return this.drawTitle || this.tit
  //     },
  //     ifUpdate() {
  //         return this.updateId || this.update_id
  //     }
  // },
  // watch: {
  //     "drawId": {
  //         handler: function(val) {
  //             if (val) {
  //                 // 禁用这个方法了
  //                 // this.getDrawInfo()
  //             }
  //         },
  //         immediate: true,
  //         deep: true
  //     }
  // },
  data() {
    return {
      radio: parseInt(domPixelRatio()) || 1
      // img: '',
      // tit: '',
      // update_id: ''
    };
  },
  methods: {
    goDrawInfo() {
      // eslint-disable-next-line no-undef
      // location.href = userHeader.mdlink;
      if (this.drawInfo.update_id > 0) {
        MDRouter.goUpdateDetail(this.drawInfo.update_id);
      } else {
        MDRouter.goDraw(this.drawInfo.activity_id);
      }
    },
    imgUrlFilter() {
      return imgUrlFilter(...arguments);
    }
    // getDrawInfo() {
    //     if(this.drawInfo && this.drawInfo.activity_id) return
    //     getNewDrawInfo({
    //         activity_id: this.drawId
    //     }).then(res => {
    //         if (res.status == 0) {
    //             this.img = res.data.img
    //             this.tit = res.data.title
    //             this.update_id = res.data.update_id
    //         }
    //     })
    // }
  }
};