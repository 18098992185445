import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5e47f9a6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "time"
};
const _hoisted_2 = {
  class: "time"
};
const _hoisted_3 = {
  key: 0,
  class: "top"
};
const _hoisted_4 = {
  key: 0,
  class: "title"
};
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  key: 0,
  class: "aliPlayer"
};
const _hoisted_8 = {
  key: 4,
  class: "img-tips"
};
const _hoisted_9 = {
  key: 5,
  class: "vote"
};
const _hoisted_10 = {
  key: 6,
  class: "draw"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_divider = _resolveComponent("van-divider");
  const _component_CompVideo = _resolveComponent("CompVideo");
  const _component_van_image = _resolveComponent("van-image");
  const _component_van_loading = _resolveComponent("van-loading");
  const _component_Quest = _resolveComponent("Quest");
  const _component_DrawNew = _resolveComponent("DrawNew");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.update_list, (item, updateIndex) => {
    return _openBlock(), _createElementBlock("div", {
      class: "list",
      key: updateIndex
    }, [_createElementVNode("div", _hoisted_1, [item.isOrderThanNow ? (_openBlock(), _createBlock(_component_van_divider, {
      key: 0,
      "content-position": "left"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.year) + " 年", 1)]),
      _: 2
    }, 1024)) : (_openBlock(), _createBlock(_component_van_divider, {
      key: 1
    })), _createElementVNode("div", {
      class: _normalizeClass(['dateTime', {
        'older': item.isOrderThanNow
      }])
    }, [_createElementVNode("span", null, _toDisplayString(item.date), 1), _createElementVNode("span", _hoisted_2, " / " + _toDisplayString(item.month) + "月", 1), item.is_top ? (_openBlock(), _createElementBlock("div", _hoisted_3, "置顶")) : _createCommentVNode("", true)], 2), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.mix_content, (mix, mixIndex) => {
      return _openBlock(), _createElementBlock(_Fragment, {
        key: mixIndex
      }, [mix.content_type == 1 && mix.is_title == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(mix.content_value), 1)) : _createCommentVNode("", true), mix.content_type == 1 && mix.is_title == 0 ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "content",
        innerHTML: $options.dealContent(mix.content_value)
      }, null, 8, _hoisted_5)) : _createCommentVNode("", true), mix.content_type == 3 ? (_openBlock(), _createElementBlock("div", {
        key: 2,
        class: "video",
        onClick: _withModifiers($event => $options.playVideo(item, mix.content_value), ["stop"])
      }, [item.if_video_play ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_CompVideo, {
        class: "videoController",
        video_url: item.video.videoUrl
      }, null, 8, ["video_url"])])) : _createCommentVNode("", true), _createVNode(_component_van_image, {
        "lazy-load": "",
        class: "img",
        src: _ctx.$imgUrlFilter(mix.video_cover, 750)
      }, null, 8, ["src"]), _createElementVNode("div", {
        class: "play-btn",
        style: _normalizeStyle(`background-image:url(${_ctx.$imgUrlFilter('https://p6.moimg.net/path/dst_project/1198698/202303/0314/4911/2303034911Kw4V0eZ7namz85r8lBvboRADkG5Y8J.png', 112, 112)});`)
      }, null, 4)], 8, _hoisted_6)) : _createCommentVNode("", true), mix.content_type == 2 ? (_openBlock(), _createBlock(_component_van_image, {
        key: 3,
        "lazy-load": "",
        class: "img",
        src: _ctx.$imgUrlFilter(mix.content_value, 750)
      }, {
        loading: _withCtx(() => [_createVNode(_component_van_loading, {
          type: "spinner",
          size: "20"
        })]),
        _: 2
      }, 1032, ["src"])) : _createCommentVNode("", true), mix.content_type == 2 && mix.describe ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(mix.describe), 1)) : _createCommentVNode("", true), mix.content_type == 4 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_Quest, {
        id: item.update_id,
        quest_info: item.quest_info && item.quest_info.find(obj => obj.id === Number(mix.content_value)),
        vote_type: '1'
      }, null, 8, ["id", "quest_info"])])) : _createCommentVNode("", true), mix.content_type == 5 ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_DrawNew, {
        drawInfo: $options.getDrawInfo(mix.content_value, item.draw_list)
      }, null, 8, ["drawInfo"])])) : _createCommentVNode("", true)], 64);
    }), 128))])]);
  }), 128);
}