import { showVideoList } from "@/api";
import CompVideo from "@@/project/video.vue";
import { getListOne } from "@/utils/tools";
import { Divider } from 'vant';
import Quest from "@@/update/detail/quest.vue";
import DrawNew from "@@/update/detail/drawNew.vue";
export default {
  props: {
    update_list: {
      type: Array,
      default: () => []
    }
  },
  components: {
    [Divider.name]: Divider,
    CompVideo,
    Quest,
    DrawNew
  },
  data() {
    return {
      currentIndex: 1
    };
  },
  computed: {},
  methods: {
    // 播放视频
    async playVideo(item, url) {
      if (item.if_video_play) {
        return false;
      }
      const res = await showVideoList({
        url: url
      });
      if (res.status == 0) {
        this.update_list.forEach(ele => {
          ele.if_video_play = false;
        });
        item.if_video_play = true;
        let defaultValue = res.data.default ? res.data.default : getListOne(res.data.list);
        let new_src = res.data.list[defaultValue];
        item.video.videoUrl = new_src;
      }
    },
    showImg(item) {
      item.if_video_play = false;
    },
    dealContent(str) {
      if (str) {
        return str.replace(/↵/g, '<br/>').replace(/\n/g, '<br/>');
      } else {
        return str;
      }
    },
    getDrawInfo(id, list) {
      return list.find(item => item.activity_id == id);
    }
  }
};